import React, { Suspense, useEffect, useRef, useState } from 'react';
// import M1Room from './M1_room.png';
// import TL1 from './timeline_1.svg';
// import TL2 from './timeline_2.svg';
// import TL3 from './timeline_3.svg';
// import TL4 from './timeline_4.svg';
// import TL5 from './timeline_5.svg';
// import TL6 from './timeline_6.svg';
// import { ReactComponent as IconService } from './icon_service.svg';
// import { ReactComponent as IconSocialConnectivity } from './icon_social_connectivity.svg';
// import { ReactComponent as IconRemoteCare } from './icon_remote_care.svg';
// import Nurse_M1 from "./Nurse_M1.png"
// import R1_Restaurant from './R1_Restaurant.png';
import HeroMov from './Hero.mov';
import HeroWEBM from './hero.webm';
import HeroPoster from './hero_poster.png';
import { ReactComponent as IconFacebook } from './icon_facebook.svg';
import { ReactComponent as IconTwitter } from './icon_twitter.svg';
import { ReactComponent as IconInstagram } from './icon_instagram.svg';
import { ReactComponent as IconLinkedin } from './icon_linkedin.svg';
import { ReactComponent as IconYouTube } from './icon_youtube.svg';
import { ReactComponent as IconEmail } from './icon_email.svg';
import { ReactComponent as IconHome } from './icon_home.svg';
import { ReactComponent as IconGear } from './icon_gear.svg';
import './App.css';
import Navbar from './components/Navbar.js';
import Section from './components/Section.js';
// import { TeamMember, TeamView } from './components/TeamMember';
// import Timeline from './components/Timeline';
// import ContactForm from './components/ContactForm';
// import { RobotBuilder } from './components/RobotBuilder';
// import background from "./assets/background.png";
// import Product from './components/Product';
// import Advantage from './components/Advantage';
const Lottie = React.lazy(() => import("react-lottie-player"));
const ContactForm = React.lazy(() => import('./components/ContactForm'));
const Product = React.lazy(() => import('./components/Product'));
const Advantage = React.lazy(() => import('./components/Advantage'));
const TeamView = React.lazy(() => import('./components/TeamMember').then(module => { return { default: module.TeamView } }));
const TeamMember = React.lazy(() => import('./components/TeamMember').then(module => { return { default: module.TeamMember } }));

function useIntersectionObserver(
  elementRef,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }
) {
  const [entry, setEntry] = useState();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]) => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}

// function debounce(fn, ms) {
//   let timer
//   return _ => {
//     clearTimeout(timer)
//     timer = setTimeout(_ => {
//       timer = null
//       fn.apply(this, arguments)
//     }, ms)
//   };
// }

function App() {
  const Fragment = React.Fragment;
  // https://stackoverflow.com/a/62497293
  const [scrollDir, setScrollDir] = useState("down");
  const [lastScrollY, setScrollY] = useState(0);
  const [loading, setLoading] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const youtubeRef = useRef();
  const youtubeSrc = 'https://www.youtube-nocookie.com/embed/z0v54mJu2DU'
  const [vidSrc, setVidSrc] = useState("");

  const animRef1 = useRef();
  const animRef2 = useRef();

  const animData1 = useIntersectionObserver(animRef1, { freezeOnceVisible: false });
  const animData2 = useIntersectionObserver(animRef2, { freezeOnceVisible: false });

  const vidRef = useRef()
  const vidData = useIntersectionObserver(vidRef, { freezeOnceVisible: false });

  useEffect(() => {
    let ticking = false;

    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
      ticking = false;
    };

    const onResize = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScreenSize);
        ticking = true;
      }
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      document.head.append(`
        <!-- https://fonts.gstatic.com is the font file origin -->
        <!-- It may not have the same origin as the CSS file (https://fonts.googleapis.com) -->
        <link rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin />

        <!-- We use the full link to the CSS file in the rest of the tags -->
        <link rel="preload"
        as="style"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap" />

        <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap"
        media="print" onload="this.media='all'" />

        <noscript>
        <link rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap" />
        </noscript>
      `)
    } else {
      document.head.append(`
        <!-- https://fonts.gstatic.com is the font file origin -->
        <!-- It may not have the same origin as the CSS file (https://fonts.googleapis.com) -->
        <link rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin />

        <!-- We use the full link to the CSS file in the rest of the tags -->
        <link rel="preload"
        as="style"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800;900&display=swap" />

        <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800;900&display=swap"
        media="print" onload="this.media='all'" />

        <noscript>
        <link rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800;900&display=swap" />
        </noscript>
      `)
    }
  }, [])

  useEffect(() => {
    const threshold = 0;
    setScrollY(window.pageYOffset);
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      setScrollY(scrollY > 0 ? scrollY : 0);
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);
    // console.log(scrollDir);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir, lastScrollY]);

  useEffect(() => {
    if (!loading)
      return;

    document.body.style.overflowY = 'hidden';
    document.body.style.height = '100%';
    const realLogo = document.querySelector('#logo');
    realLogo.classList.add("opacity-0");

    setTimeout(function () {
      realLogo.classList.remove("opacity-0");
      document.body.style.overflowY = '';
      document.body.style.height = '';
      setLoading(false);
    }, 3500)

  }, [loading]);

  return (
    <div id="app" className="App">
      {loading &&
        <div className="loader fixed w-screen h-screen z-[9999] top-0 left-0 right-0 bottom-0">
          <img src={require('./logo.svg').default} alt="Xtend AI Logo" />
          <h1 className="text-5xl fixed bottom-[25vh] left-1/2 -translate-x-1/2">PRESENTS</h1>
        </div>
      }
      <Navbar scrollDir={scrollDir} scrollY={lastScrollY} />
      <Section name="home" className="relative min-h-[600px] lg:min-h-screen bg-intro-bg bg-cover bg-center sm:bg-right flex text-center flex-row place-content-center flex-wrap overflow-hidden">
        <div className="w-full min-h-[680px] lg:min-h-screen relative animate-fade-in pt-20">
          <div ref={vidRef} className="max-h-[50vh] sm:max-h-[80vh] 2xl:max-h-[99vh] w-full absolute grid sm:grid-cols-[1fr,1fr] 2xl:grid-cols-[1fr,2fr] justify-center sm:items-end">
            {!vidData?.isIntersecting ?
              <img src={HeroPoster} className="relative sm:col-start-2 -mt-12 sm:mt-0 z-10 w-auto h-auto max-h-[50vh] sm:max-h-[80vh] 2xl:h-[90vh] 2xl:max-h-[90vh]" alt="Intro poster" />
              :
              <video id="vid" className="relative sm:col-start-2 -mt-12 sm:mt-0 z-10 w-auto h-auto max-h-[50vh] sm:max-h-[80vh] 2xl:h-[90vh] 2xl:max-h-[90vh]" autoPlay={true} loop muted playsInline poster={HeroPoster}>
                <source src={HeroMov} type='video/mp4; codecs="hvc1"' />
                <source src={HeroWEBM} type="video/webm" />
              </video>
            }
          </div>
          <div className="absolute bottom-8 left-4 sm:bottom-14 sm:left-20 z-20">
            <a href="#video" aria-label="Play Xtend AI Presentation Video" onClick={(ev) => {
              // if (youtubeRef.current) {
              setVidSrc("")
              setTimeout(() => {
                setVidSrc(youtubeSrc + "?autoplay=1")
              }, 100)
              // }
            }} className="flex text-white font-bold bg-[#03A9F4] rounded-full w-48 px-2 gap-4 py-2 items-center mb-8">
              <img src={require('./assets/play_icon.svg').default} alt="" />
              <span>PLAY VIDEO</span>
            </a>
            <span className="inline-block font-extrabold text-left text-5xl md:text-7xl lg:text-8xl 2xl:text-9xl text-white pb-2 2xl:pb-3 text-gradient">Mobile<br />Intelligence</span>
          </div>
        </div>
      </Section>
      <Section name="solutions" className="relative min-h-screen p-5 lg:p-20 max-sm:[background:linear-gradient(143.26deg,_#D3E3FB_-8.3%,_#CACDFA_15.43%,_#CCD0FA_36%,_#DDECFD_53.51%,_#DDE9FD_70.2%,_#D6D2FE_89.05%)] sm:bg-bg-2 bg-center bg-cover flex flex-col items-center justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-5 gap-x-12 justify-center max-w-5xl 2xl:max-w-7xl mx-auto">
          <div className="lg:col-span-4 text-left self-center">
            {/* <img className="h-16 mb-4" src={require('./logo.svg').default} alt="Logo" /> */}
            <p className="text-[#0C0090] text-xl lg:leading-[3rem] pt-5 lg:text-2xl 2xl:text-4xl 2xl:leading-[3.25rem] font-bold">As the awareness and deployment of Service Robots across multiple industries has emerged worldwide, Xtend&apos;s extensive portfolio of patented technologies that leverages our completely autonomous AI &ldquo;brain&rdquo; that powers our Robots and can be migrated into any hardware form factor, represents a paradigm shift from costly, non-intelligent, cloud dependent, single purpose Robots and devices.</p>
          </div>
          <div className="lg:col-span-3 grid grid-rows-2 text-center font-semibold text-sm 2xl:text-xl leading-5 2xl:leading-7">
            <div ref={animRef1} className="flex-grow flex flex-col basis-0 items-center p-3 gap-5">
              <Suspense>
                <div className="relative w-32 h-32 p-4 flex place-items-center bg-white rounded-full before:content-['_'] before:bg-[#024F88] before:mix-blend-multiply before:opacity-[0.79] before:blur-[27.1828px] before:w-24 before:h-24 before:absolute before:-bottom-1 before:left-1/2 before:-translate-x-1/2 before:-z-10">
                  {!animData1?.isIntersecting ?
                    <Lottie animationData={require('./assets/ai_icon.json')} className="max-h-28" play={false} direction={-1} goTo={0} loop={false} />
                    :
                    <Lottie animationData={require('./assets/ai_icon.json')} className="max-h-28" play={true} loop={false} />
                  }
                </div>
              </Suspense>
              <p>Our Brain & Behavior multi-tasking architecture enables our Robots and Kiosks to perform multiple tasks in parallel with full OTA upgradability.</p>
            </div>
            <div ref={animRef2} className="flex-grow flex flex-col basis-0 items-center p-3 gap-5">
              <Suspense>
                <div className="relative w-32 h-32 p-4 flex place-items-center bg-white rounded-full before:content-['_'] before:bg-[#024F88] before:mix-blend-multiply before:opacity-[0.79] before:blur-[27.1828px] before:w-24 before:h-24 before:absolute before:-bottom-1 before:left-1/2 before:-translate-x-1/2 before:-z-10">
                  {!animData2?.isIntersecting ?
                    <Lottie animationData={require('./assets/offline_icon.json')} className="max-h-28" play={false} direction={-1} goTo={0} loop={false} />
                    :
                    <Lottie animationData={require('./assets/offline_icon.json')} className="max-h-28" play={true} loop={false} />
                  }
                </div>
              </Suspense>
              <p>Our patented &ldquo;Local&rdquo; offline AI engine enables our Robots, Kiosks and other devices to operate without connectivity to the internet or cloud, enabling them to work offline, in virtually all facilities and environments.</p>
            </div>
          </div>
        </div>
      </Section>
      <Section name="video" className="relative flex flex-col bg-[#F7F9FF] justify-center items-center p-5">
        <h1 className="text-4xl lg:text-5xl 2xl:text-6xl font-semibold lg:font-bold text-left mb-3 text-[#3609D0] w-full lg:w-[70%] lg:-mb-[8%] lg:mt-8">What Sets Us Apart</h1>
        <div className="iframe-container">
          {vidSrc ?
            <iframe
              ref={youtubeRef} className="absolute w-full h-full top-0 left-0 lg:w-[70%] lg:h-[70%] lg:left-[15%] lg:top-[18%]" width="560" height="315" src={vidSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen={true}>
            </iframe>
            :
            <div className="cursor-pointer" role="button" aria-label="Play Xtend AI Presentation Video" onClick={() => setVidSrc(youtubeSrc + "?autoplay=1")}>
              <img className="absolute w-full h-full top-0 left-0 lg:w-[70%] lg:h-[70%] lg:left-[15%] lg:top-[18%]" width="560" height="315" loading="lazy" src="https://img.youtube.com/vi/bKFw_fNNVuE/maxresdefault.jpg" alt='Xtend AI Presentation' />
              <img className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-16 h-16" src={require('./assets/play_icon.svg').default} alt="" />
            </div>
          }
        </div>
      </Section>
      <Section className="relative text-left min-h-screen p-5 lg:p-20 flex flex-col items-center justify-center">
        <div className="absolute bg-[radial-gradient(120.43%_46.96%_at_103.02%_52.45%,_#02E7FD_0%,_#532ECA_100%),_linear-gradient(210deg,_#532ECA_66.67%,_#5352F0_75%,_#A1AAFD_80.32%,_#A7AEFF_88.14%,_#676AF0_96.32%)] bg-[top_bottom] bg-[100%_60%,100%_100%] sm:bg-bg-3 bg-no-repeat sm:bg-cover transform-gpu sm:bg-right-bottom lg:bg-center top-0 left-0 right-0 bottom-0 -z-10"></div>
        <div className="max-w-6xl flex flex-col 2xl:w-full max-lg:text-center">
          <h1 className="text-4xl lg:text-5xl 2xl:text-6xl font-bold mb-3 text-white">Customize Everything</h1>
          <h2 className="text-xl lg:text-2xl font-bold mb-8 text-white">Products in Development Using Our &ldquo;Brain&rdquo;</h2>
          <div className="flex flex-col sm:flex-row flex-wrap items-center justify-center 2xl:justify-between sm:items-baseline gap-12">
            <Suspense>
              <Product
                src={require('./assets/products/M1.png')}
                alt={"Xtend M1 Nurses' Assistant Robot"}
                title={"M1"}
                text={<>Nurses&apos;<br />Assistant</>}
              />
              <Product
                src={require('./assets/products/R1.png')}
                alt={"Xtend Intelligent Restaurant Robot"}
                title={"R1"}
                text={<>Intelligent<br />Restaurant<br />Robot</>}
              />
              <Product
                src={require('./assets/products/M2.png')}
                alt={"Xtend M2 Medication Dispensing Robot"}
                title={"M2"}
                text={<>Medication<br />Dispensing<br />Robot</>}
              />
              <Product
                src={require('./assets/products/Kiosk.png')}
                alt={"Xtend Intelligent Medical Kiosk"}
                title={"Kiosk"}
                text={<>Intelligent<br />Medical<br />Kiosk</>}
              />
              <Product
                src={require('./assets/products/App.png')}
                alt={"Xtend Intelligent Stethoscope App"}
                title={"Stethoscope"}
                text={<>Intelligent<br />Stethoscope &amp; App</>}
              />
            </Suspense>
          </div>
        </div>
      </Section>
      <Section name="overview" className="relative min-h-screen p-5 lg:p-20 max-sm:[background:linear-gradient(153.54deg,_#F7F9FF_6.74%,_#F6F8FF_53.95%,_#D1C8FE_64.46%,_#A391FE_79.61%)_bottom] sm:bg-bg-4 sm:bg-center bg-cover max-lg:max-w-[100vw] max-lg:overflow-hidden">
        <div className="grid grid-cols-1 lg:grid-cols-8 text-left gap-y-10 gap-x-2 lg:gap-x-5 max-w-6xl mx-auto">
          <h1 className="font-bold text-custom-purple text-4xl lg:text-5xl 2xl:text-6xl lg:col-start-1 lg:col-end-5 lg:-mt-2">Business Overview</h1>
          <p className="font-bold text-custom-purple text-2xl leading-9 col-start-1 row-start-2 lg:col-end-5 sm:grid-cols-1 lg:row-end-4">Currently deployed service robots are designed to provide mission-specific, single-task capability with limited intelligence, resulting in high costs, inflexibility, and built-in obsolescence.</p>
          <div className="lg:row-start-4 lg:col-start-1 lg:col-end-5">
            <img className="max-h-16 mb-2" src={require('./assets/icon_mission.svg').default} alt="" loading="lazy" height={64} />
            <p className="text-lg font-medium leading-6">
              Our mission has been to develop affordable, customizable, and upgradeable intelligent autonomous robots that address the specific needs of each industry and sector, resulting in immediate integration in the workplace.
            </p>
          </div>
          <div className="lg:row-start-4 lg:col-start-5 lg:col-end-9">
            <img className="max-h-16 mb-2" src={require('./assets/icon_ai.svg').default} alt="" loading="lazy" height={64} />
            <p className="text-lg font-medium leading-6">
              We have met these objectives by developing a patented modular architecture consisting of common hardware and AI packages that all our products share, resulting in unmatched time-to-market and cost efficiencies.
            </p>
          </div>
          <div className="max-lg:row-start-2 max-lg:col-start-1 max-lg:relative max-lg:h-full max-lg:w-full flex lg:h-auto lg:col-start-5 lg:row-start-1 lg:row-end-4 lg:-mt-24 lg:col-end-9 items-start">
            <img className="max-md:h-36 max-md:bottom-8 max-lg:h-40 max-lg:absolute max-lg:bottom-12 max-lg:-right-16 max-lg:translate-y-full" src={require('./assets/big_icon.svg').default} alt="" loading="lazy" />
          </div>
          <div className="lg:col-start-1 lg:col-end-9 lg:row-start-5 lg:row-end-6 grid lg:grid-cols-2 lg:grid-rows-[auto,1fr,1fr,1fr] gap-4">
            <h2 className="font-bold text-custom-purple text-3xl lg:text-4xl 2xl:text-5xl lg:col-start-1 lg:col-end-3">Advantages</h2>
            <Suspense>
              <Advantage
                src={require('./assets/Advantages/icon_offline.svg').default}
                alt={"Offline Operation"}
                text={<>The &ldquo;Local&rdquo; offline AI engine enables our robots, kiosks and other devices to operate without connectivity to the internet or cloud, enabling them to Work Offline</>}
              />
              <Advantage
                src={require('./assets/Advantages/icon_proactive.svg').default}
                alt={"Proactive Brain"}
                text={<>Xtend AI brain with PRO-Active prompts</>}
              />
              <Advantage
                src={require('./assets/Advantages/icon_brain.svg').default}
                alt={"Multi-tasking Brain"}
                text={<>Our &ldquo;Brain & Behavior&rdquo; multi-tasking architecture - enables our robots and kiosks to PRO-Actively interact with users while simultaneously Multi-tasking, with full OTA upgradability</>}
              />
              <Advantage
                src={require('./assets/Advantages/icon_calendar.svg').default}
                alt={"Quick development cycle"}
                text={<>12 months Development Cycle from Concept to Market</>}
              />
              <Advantage
                src={require('./assets/Advantages/icon_modular.svg').default}
                alt={"Modular Architechture"}
                text={<>Proprietary Modular Architecture enables us to develop Custom Products in a Fraction of the Time and Cost</>}
              />
              <Advantage
                src={require('./assets/Advantages/icon_upgradeable.svg').default}
                alt={"Upgradeable"}
                text={<>Xtend robots are Fully Upgradeable</>}
              />
            </Suspense>
          </div>
        </div>
      </Section>
      <Section name="team" className="min-h-screen flex flex-col lg:justify-center relative max-lg:bg-[linear-gradient(-226.84deg,_#502FE7_-2.64%,_#6760DD_17.78%,_#4B31CB_58.98%,_#08D6FC_94.82%)] bg-cover lg:bg-custom-grey py-8 p-5 sm:p-20 overflow-hidden">
        <Suspense>
          <TeamView hasBio={true} title="Management Team" titleColor="text-white lg:text-custom-purple" screenWidth={screenWidth}>
            <TeamMember
              name="Harry Fox"
              position="CEO &amp; Founder"
              text="Harry's career spans more than 40 years in the Consumer Electronics and Digital Media businesses, with countless innovative products as a successful serial entrepreneur to his credit."
            />
            <TeamMember
              name="Estelle Cleary"
              position={<>Executive Vice President,<br />Corporate Secretary</>}
              text="Estelle has over 30 years of experience as a senior executive in the field of consumer electronics, computers and software development. She was responsible for all due diligence for the successful acquisition of Photags, Inc. by Smith Micro Software Inc (2006) and Future Vision Inc. by The Learning Company (Mattel, Inc.) (1995).
            Estelle has served as a Member of the Board of Directors for multiple technology companies and holds a B.S. in accounting from SUNY, Old Westbury."
            />
            <TeamMember
              name="David Azoulay"
              position={<Fragment>VP Business,<br />Product Development</Fragment>}
              text="David has over 8 years of experience in project management, engineering, and product development and strategy. He has been an entrepreneur in the world of e-commerce, involved in startups and successful companies. His MA in Technology Entrepreneurship and BSc in Mechanical and Mechatronics Engineering give him a deep and wide understanding of the development of tech companies."
            />
            <TeamMember
              name="Addison Sears-Collins"
              position="CTO"
              text="Addison has 15+ years of experience in product development and team management, specializing in robotics, computer vision, and deep learning.
              As a senior executive at 21212 LLC, Brazil's pioneering technology startup accelerator, Addison led the acquisitions of tech startups like ZeroPaper (acquired by Intuit) and PebMed (acquired by Afya). He has also worked at Siemens, Goldman Sachs, and prestigious universities.
              Addison is the founder of AutomaticAddison.com, the leading robotics education blog with around 50,000 monthly visitors.
              He holds an M.S. in Computer Science from Johns Hopkins University and a B.A. in Physics from the University of Virginia."
            />
            <TeamMember
              name="Jacob Benjamin"
              position="Director of Software Development"
              text="Jacob has been involved in the field of software for almost 40 years. Through his career he has both developed software and managed large teams of developers on multi platform projects. He has been involved in an extensive range of products, ranging from Business software, through anti-virus, computer aided learning, and content management systems that have run on many different platforms. Jacob brings with him a wealth of management experience in software development and processes."
            />
            <TeamMember
              name="Boris Zlotnikov"
              position="Director of Engineering"
              text="Boris leads the engineering teams in the development of cutting-edge robotic systems and technologies. With over 15 years of experience in the field, Boris brings a wealth of knowledge and expertise in multidisciplinary R&D and production. Under Boris's leadership, our company is poised to revolutionize the robotics industry with innovative and transformative solutions."
            />
            <TeamMember
              name="Ronnie Wulfsohn"
              position="CTO Emeritus"
              text="With over 40 years of experience on the cutting edge of the computer revolution, Ronnie is a master of many technologies. Based on his strong background in mathematics, he is also an ace at solving complex problems, from low-end coding issues to the global aspects of system architecture. Among his many achievements are: the development of one of the early search engines; the first ActiveX DVD player built together with Microsoft; invention, patent and implementation of Photags which enabled erasable captions on JPEG images and was sold to Smith Micro; patent of AnyLync technology."
            />
            <TeamMember
              name="Betzalel Gersten"
              position="Director of Sales"
              text="After more than 20 years in the not-for-profit sector, as Executive Director for organizations such as American Friends of Mosdos Boston, Derech Etz Chaim, Yeshivas Mishkan Yisroel, et. al., Betzalel brings his acumen and tenacity to Xtend AI as our Sales Director and Executive Assistant to the CEO."
            />
            <TeamMember
              name="Mandy Nurek"
              position={<Fragment>Director of Operations,<br />Human Resources</Fragment>}
              text="Mandy has over twenty years&apos; experience in Human Resource Management and administration organization. Mandy has extensive experience assisting startups from the R&D stage to fully operating companies."
            />
          </TeamView>
        </Suspense>
      </Section>
      <Section name="contact" className="min-h-screen relative bg-cover p-5 sm:p-20 lg:pt-28 xl:pt-36 flex flex-col items-center justify-center">
        {/* border-l-[30px] border-t-[40px] md:border-l-[90px] md:border-t-[70px] xl:border-l-[160px] xl:border-t-[120px] */}
        <div className="absolute top-0 bottom-0 left-0 right-0 z-[-1] max-sm:[background:linear-gradient(0deg,_#000000_11.82%,_#520ECA_100%)] sm:bg-bg-5 bg-cover bg-center pointer-events-none">
          <div className="absolute bottom-0 right-0 left-[30px] top-[90px] md:left-[90px] md:top-[70px] xl:left-[160px] xl:top-[120px] bg-white -z-10"></div>
          <div className="pointer-events-none overflow-auto absolute md:-translate-x-full h-12 w-12 left-[30px] top-[36px] md:h-16 md:w-16 md:left-[90px] md:top-[70px] xl:left-[160px] xl:top-[120px] z-10">
            <img className="absolute object-cover w-16 h-16 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" src={require('./assets/conact_icon.svg').default} alt="" />
          </div>
        </div>
        <div className="grid grid-flow-row md:grid-cols-2 md:grid-flow-col max-w-3xl mx-auto">
          <div className="text-left p-5">
            <h1 className="max-md:mt-12 text-3xl lg:text-4xl 2xl:text-5xl mb-5 text-custom-purple font-bold">Contact Us</h1>
            <p className="mb-3">You can follow us on social media or use traditional means of communication like email or post</p>
            <div className="flex no-wrap gap-4">
              <a aria-label="Facebook" href="https://www.facebook.com/xtendrobotics"><IconFacebook className="h-7 w-7 p-1 ml-[-2px] inline-block" /></a>
              <a aria-label="Twitter" href="https://twitter.com/Xtend_Robotics"><IconTwitter className="h-7 w-7 p-1 inline-block" /></a>
              <a aria-label="Instagram" href="https://www.instagram.com/xtendrobotics/"><IconInstagram className="h-7 w-7 p-1 inline-block" /></a>
              <a aria-label="LinkedIn" href="https://www.linkedin.com/company/xtend-robotics/"><IconLinkedin className="h-7 w-7 p-[1px] inline-block" /></a>
              <a aria-label="YouTube" href="https://www.youtube.com/channel/UCZTRJoLGK5e3CT7Jn6IJyTQ?view_as=subscriber"><IconYouTube className="h-7 w-7 p-[-1px] inline-block" /></a>
              <a aria-label="Email" href="mailto:info@xtendai.tech"><IconEmail className="h-7 w-7 p-[1px] inline-block" /></a>
            </div><br />
            <div className="flex flex-col">
              <span className="inline-block mb-4">
                <IconHome className="h-7 w-7 inline-block my-2 mr-2" /><b>HEADQUARTERS</b><br />
                1727 Hollow Glen Drive<br />
                Middleburg, Florida 32068<br />
              </span>
              {/* ISRAELI HEADQUARTERS<br />
              11 Rivka Street,<br />
              Jerusalem 9346117, Israel<br /><br /> */}

              <span className="inline-block mb-4">
                <IconGear className="h-7 w-7 inline-block my-2 mr-2" /><b>R&amp;D CENTER</b><br />
                2 A. D. Bergman Street,<br />
                Rechovot 7670503, Israel<br />
              </span>

              <span className="inline-block">
                <IconEmail className="h-7 w-7 inline-block my-2 mr-2" /><a aria-label="Contact Email" href="mailto:info@xtendai.tech">info@xtendai.tech</a>
              </span>
            </div>
          </div>
          <Suspense>
            <ContactForm />
          </Suspense>
        </div>
      </Section>
      <div className="bg-black h-16 flex p-5 flex-row justify-center items-center text-white">
        <p>Copyright &copy; Xtend AI&trade; {new Date().getUTCFullYear()}. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default App;
